<template>
  <div>
    <div
      class="tariffs__classic-and-smart"
      :style="{
        'flex-direction':
          $breakpoint.fromThreeColumns() && (!useRowLayout || useShortRowLayout)
            ? 'row'
            : 'column'
      }"
    >
      <Tariff
        v-for="tariff in tariffs"
        :key="tariff.id"
        :title="promotionAdvertisingTitle(tariff)"
        :teaser="teaser(tariff)"
        :highlight="showHighlight(tariff)"
        :features="abTestBased(tariff)"
        :button-text="buttonText(tariff)"
        :price="tariff.price"
        :footnote="tariff.legal.footnoteId"
        :pip-path="tariff.pipPath"
        :subtitle="tariff.title"
        :slug="tariff.slug"
        :row="useRowLayout"
        :short-row="useShortRowLayout"
        :full-row="fullRow"
        :heading-level="headingLevel"
        :custom-order-url="tariff.customOrderUrl"
        :is5g="tariff.networkTechnology.toLowerCase() === '5g'"
      />
    </div>
  </div>
</template>

<script>
import Tariff from './tariff.vue'

export default {
  name: 'TariffGroup',
  components: {
    Tariff
  },
  props: {
    tariffs: {
      type: Array,
      default: undefined
    },
    row: {
      type: Boolean,
      default: false
    },
    shortRow: {
      type: Boolean,
      default: false
    },
    fullRow: {
      type: Boolean,
      default: false
    },
    headingLevel: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      additionalData: {
        TARIFF_ALLNET_FLAT: {
          features: [
            'Die ersten 4 Wochen kostenlos',
            'Telefon- & SMS-Flatrate'
          ],
          buttonTextPrefix: 'Zum'
        },
        TARIFF_PRE_PAID: {
          features: ['10 € Startbonus', '24 Cent pro MB'],
          buttonTextPrefix: 'Zum'
        },
        TARIFF_DATA_FLAT: {
          features: [
            'Die ersten 4 Wochen kostenlos',
            'Internet-Flatrate',
            'Keine Vertragslaufzeit',
            '9 Cent/Minute und SMS'
          ],
          buttonTextPrefix: 'Zur'
        },
        TARIFF_DATA_FLAT_TAGESFLAT: {
          features: ['10 € Startbonus', 'Internet-Flatrate pro Nutzungstag'],
          buttonTextPrefix: 'Zur'
        },
        TARIFF_SMART: {
          features: [
            '4 Wochen kostenlos & 5 € Startbonus',
            '100 Frei-Min./SMS in Deutschland & EU',
            'Internet-Flatrate'
          ],
          buttonTextPrefix: 'Zum'
        }
      },
      tariffTeaserInfo: {
        FONIC_PRIME_SMART_S: 'Jetzt noch mehr GB',
        FONIC_PRIME_SMART_M: 'Jetzt noch mehr GB',
        FONIC_PRIME_SMART_L: 'Jetzt noch mehr GB',
        LIDL_PRIME_SMART_S: 'Jetzt noch mehr GB',
        LIDL_PRIME_SMART_M: 'Jetzt noch mehr GB',
        LIDL_PRIME_SMART_L: 'Jetzt noch mehr GB',
        FONIC_SMART_S: 'Unser Bestseller',
        LIDL_SMART_S_300_300: 'Unser Bestseller'
      },
      highlightTariffs: ['FONIC_SMART_S', 'LIDL_SMART_S_300_300'],
      promotionTitle: {}
    }
  },
  computed: {
    useRowLayout() {
      return (
        this.$breakpoint.desktop() ||
        (this.$breakpoint.fromThreeColumns() && this.row)
      )
    },
    useShortRowLayout() {
      return (
        this.$breakpoint.tablet() ||
        (this.$breakpoint.fromThreeColumns() && this.shortRow)
      )
    },
    teaser() {
      return tariff => {
        if (Object.keys(this.tariffTeaserInfo).includes(tariff.id)) {
          return this.tariffTeaserInfo[tariff.id]
        } else return ''
      }
    },
    showHighlight() {
      return tariff => {
        return this.highlightTariffs.includes(tariff.id)
      }
    },
    features() {
      return tariff => {
        if (tariff.id === 'FONIC_DAY_FLAT') {
          return this.additionalData.TARIFF_DATA_FLAT_TAGESFLAT.features
        } else {
          return this.additionalData[tariff.type].features
        }
      }
    },
    buttonText() {
      return tariff => {
        if (tariff.id === 'FONIC_DAY_FLAT') {
          return `${this.additionalData.TARIFF_DATA_FLAT_TAGESFLAT.buttonTextPrefix} ${tariff.title}`.replace(
            this.$brand.name,
            ''
          )
        } else {
          return `${this.additionalData[tariff.type].buttonTextPrefix} ${
            tariff.title
          }`.replace(this.$brand.name, '')
        }
      }
    },
    promotionAdvertisingTitle() {
      return tariff => {
        if (Object.keys(this.promotionTitle).includes(tariff.id)) {
          return this.promotionTitle[tariff.id]
        } else return tariff.advertisingTitle
      }
    },
    //Remove once AB-Test "homeRedesign" is finished
    abTestBased() {
      return tariff => {
        const features = this.features(tariff)

        return this.fullRow ? features : features.slice(0, 2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tariffs__classic-and-smart {
  display: flex;
  margin-top: 24px;
}
</style>
