<template>
  <div
    class="tariff__wrapper"
    :class="[
      highlight && 'teaser',
      row && 'row-layout',
      (shortRow || useFullRowLayout) && 'short-row-layout',
      useFullRowLayout && 'full-row-layout'
    ]"
  >
    <div class="tariff__info-section">
      <div class="tariff__title-section">
        <component
          :is="`h${headingLevel}`"
          v-if="title"
          class="title tariff__title"
          :data-cy="`tariff-title-${slug}`"
          >{{ subtitle }}</component
        >
        <component
          :is="`h${headingLevel + 1}`"
          v-if="subtitle"
          class="subtitle tariff__subtitle"
          >{{ title }}</component
        >
      </div>

      <div class="tariff__bullet-section">
        <Checklist class="tariff__features">
          <li
            v-for="(feature, index) in features"
            :key="`feature${index}`"
            v-html="feature"
          ></li>
        </Checklist>
        <div v-if="is5g" class="tariff__5g-info-wrapper">
          <div class="tariff__5g-info">
            <span>Jetzt auch im </span>
            <span class="visually-hidden">5G</span>
            <img :src="Badge" class="tariff__5g-img" alt="" />
            <span>Netz</span>
          </div>
          <Footnote footnote-id="100" />
        </div>
      </div>
    </div>

    <hr v-if="!useFullRowLayout" />
    <ImageComponent
      v-if="fullRow && $brand.isFonic() && $breakpoint.fromThreeColumns()"
      :src="VisualSurfFlat"
      alt=""
      aria-hidden="true"
    />

    <div class="tariff__button-section">
      <div class="tariff__price-section">
        <Price
          v-if="price"
          class="tariff__price"
          :price="price.value"
          :period="price.hasRecurringCharge ? price.period : undefined"
          :no-recurring-costs="
            !price.hasRecurringCharge ? 'Keine Grundgebühr' : undefined
          "
          :initial-cost="'9,99'"
        />
      </div>

      <div class="tariff_button-container">
        <ButtonComponent
          v-if="$brand.isFonic()"
          key="order_a"
          :clickhandler="goToOrder"
          :button-type="highlight ? 'highlight' : 'primary'"
          class="tariff__button"
          :class="[highlight && 'teaser']"
          data-cy="tariff-button"
          :aria-label="`${subtitle} ${orderButtonText}`"
          >{{ orderButtonText }}</ButtonComponent
        >
        <ButtonComponent
          key="info_a"
          :button-type="$brand.isFonic() ? 'look-like-link' : 'primary'"
          :href="href"
          class="tariff__button"
          :class="{ 'tariff__button-look-like-link': $brand.isFonic() }"
          data-cy="tariff-button"
          :aria-label="`${moreInfoButtonText} ${buttonText}`"
          >{{ moreInfoButtonText }}
          <Icon
            v-if="$brand.isFonic()"
            name="keyboard__arrow--right"
            height="20"
            up="2"
          />
        </ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
import ABTest from 'src/components/common/a_b_test/a_b_test.vue'
import Badge from './resources/${BRAND_CODE}/5g.svg'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Checklist from 'src/components/common/checklist/checklist.vue'
import Footnote from 'src/components/common/footnote/footnote.vue'
import Icon from 'src/components/common/icon/icon.vue'
import ImageComponent from 'src/components/common/image/image.vue'
import InlineSVG from 'src/components/common/inline_svg/inline_svg.vue'
import Price from 'src/components/common/pack/components/price.vue'
import VisualSurfFlat from './resources/visual-surf-flat.webp'
export default {
  name: 'TariffComponent',
  components: {
    ABTest,
    Price,
    Checklist,
    ButtonComponent,
    Footnote,
    ImageComponent,
    Icon,
    InlineSVG
  },
  props: {
    slug: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    subtitle: {
      type: String,
      default: undefined
    },
    teaser: {
      type: String,
      default: undefined
    },
    highlight: {
      type: Boolean,
      default: false
    },
    features: {
      type: Array,
      default: () => []
    },
    pipPath: {
      type: String,
      default: undefined
    },
    price: {
      type: Object,
      default: undefined
    },
    footnote: {
      type: Number,
      default: undefined
    },
    row: {
      type: Boolean,
      default: false
    },
    shortRow: {
      type: Boolean,
      default: false
    },
    fullRow: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: undefined
    },
    headingLevel: {
      type: Number,
      default: 2
    },
    customOrderUrl: {
      type: String,
      default: undefined
    },
    is5g: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      moreInfoButtonText: 'Zum Tarif',
      orderButtonText: 'Sofort bestellen',
      VisualSurfFlat,
      Badge
    }
  },
  computed: {
    href() {
      if (this.slug) {
        return `https://www.fonic.de/prepaid-tarife/${this.slug.replace(
          'mobile-',
          ''
        )}/`
      } else {
        return undefined
      }
    },
    useFullRowLayout() {
      return this.fullRow && this.$breakpoint.fromTablet()
    }
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: 'PortalTariffDetails',
        params: { slug: this.slug }
      })
    },
    goToOrder() {
      if (this.customOrderUrl) {
        window.location.href = this.customOrderUrl
      } else {
        this.$router.push({
          name: 'CheckoutProduct',
          params: { tariffSlug: this.slug }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tariff__wrapper {
  position: relative;
  background-color: var(--background-primary);
  margin: 5px 2px;
  padding: 30px 20px 20px;
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  border-color: $input-border-color;

  @include ie-only {
    min-height: 450px;
  }

  &.teaser {
    border-color: $ribbon-color;
  }
}

.tariff__bullet-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.tariff__features {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  @include breakpoint($three-columns) {
    justify-content: flex-start;
  }
}

.tariff__info-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

hr {
  border: 0.5px solid #e9eef1;
  color: #e9eef1;
  background-color: #e9eef1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tariff__teaser {
  color: $root-color;
  position: absolute;
  top: -11px;
  padding: 2px 10px 0;
  border-radius: 6px;
  background-color: $ribbon-color;

  @include breakpoint($up-to-tablet) {
    top: -7px;
  }
}

.tariff_button-container {
  display: flex;
  flex-direction: column;
}

.tariff__price-section {
  display: flex;
  flex-direction: column;
}

.tariff__price {
  align-self: flex-start;
  margin-bottom: 10px;
  margin-left: 0;
}

.tariff__price-info {
  line-height: 1.1;
  font-size: 0.8rem;
  white-space: nowrap;

  *:first-child::after {
    content: ' ,';
  }
}

.tariff__price-info-section {
  display: flex;
  flex-direction: column;
}

.tariff__button-section {
  display: flex;
  flex-direction: column;
}

.tariff__button.button-component {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.row-layout,
.short-row-layout {
  flex-direction: row;

  @include ie-only {
    min-height: 220px;
  }

  .tariff__bullet-section {
    flex-basis: 60%;
    padding-left: 30px;
  }

  .tariff__teaser {
    top: -7px;
  }

  .tariff__features {
    margin-top: 0;
    margin-bottom: 0;
  }

  .tariff__title-section {
    flex-basis: 40%;
  }

  .tariff__info-section {
    flex-direction: row;

    @include breakpoint($up-to-desktop) {
      flex-direction: column;
      flex-basis: 70%;
    }
  }

  .tariff__price-section {
    flex-grow: 1;
    justify-content: flex-end;
    margin-left: 25px;
  }

  .tariff__button {
    margin-top: 8px;
    margin-left: 25px;
  }

  .tariff__button-section {
    position: relative;
    flex-basis: 40%;
    min-width: 190px;

    @include breakpoint($up-to-desktop) {
      flex-basis: 35%;
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      width: 2px;
      background: #e9eef1;
    }
  }
}

.short-row-layout {
  .tariff__info-section {
    flex-direction: column;
  }

  .tariff__bullet-section {
    padding-left: 0;
  }

  .tariff__features {
    margin-top: 10px;
  }

  .tariff__price {
    align-self: center;
    flex-basis: 60%;
  }

  .tariff__price-section {
    align-items: center;
  }

  .tariff__price-info-section {
    flex-flow: row wrap;

    *:first-child {
      margin-right: 4px;

      &::after {
        content: ' -';
      }
    }
  }

  .tariff__pip-download {
    align-self: flex-start;
    text-decoration: none;
  }
}

.full-row-layout {
  flex-basis: 100%;

  .tariff__button-section::before {
    content: none;
  }

  .tariff__features {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;

    @include breakpoint($from-desktop) {
      grid-template-columns: auto 1fr;
    }
  }

  .tariff__price-section {
    flex-grow: 0;
  }

  .tariff__price {
    align-self: flex-start;
    flex-basis: initial;
  }

  .tariff__button-section {
    flex-basis: 25%;
    justify-content: center;
  }
}

.tariff__button.tariff__button-look-like-link {
  align-self: center;
  margin-top: 18px;
}

.tariff__title {
  @include font-normal;

  font-size: 1rem;
}

.tariff__subtitle {
  @include font-bold;

  font-size: 22px;
}

.tariff__5g-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.tariff__5g-info {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #683a0d;
  background-color: #f7a101;
  border-radius: 5px;
  width: fit-content;
  padding: 0 10px;
  margin: 5px 0 15px;

  @include font-normal;

  span {
    position: relative;
    top: 2px;
  }
}

.tariff__5g-img {
  height: 32px;
  margin: 0 5px;

  @include fonic-mobile {
    padding: 3px 0;
  }
}

.tariff__download {
  font-size: 0.9rem;
}
</style>
